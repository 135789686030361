/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DomainValuePair } from './DomainValuePair';
import {
    DomainValuePairFromJSON,
    DomainValuePairFromJSONTyped,
    DomainValuePairToJSON,
} from './DomainValuePair';
import type { PrePackagedConceptSetEnum } from './PrePackagedConceptSetEnum';
import {
    PrePackagedConceptSetEnumFromJSON,
    PrePackagedConceptSetEnumFromJSONTyped,
    PrePackagedConceptSetEnumToJSON,
} from './PrePackagedConceptSetEnum';

/**
 * 
 * @export
 * @interface DataSetRequest
 */
export interface DataSetRequest {
    /**
     * 
     * @type {number}
     * @memberof DataSetRequest
     */
    dataSetId?: number;
    /**
     * 
     * @type {string}
     * @memberof DataSetRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DataSetRequest
     */
    description?: string;
    /**
     * Entity tag for optimistic concurrency control. To be set during a read-modify-write to ensure that the client has not attempted to modify a changed resource.
     * @type {string}
     * @memberof DataSetRequest
     */
    etag?: string;
    /**
     * 
     * @type {number}
     * @memberof DataSetRequest
     */
    workspaceId?: number;
    /**
     * Whether to include all participants or filter by cohorts
     * @type {boolean}
     * @memberof DataSetRequest
     */
    includesAllParticipants?: boolean;
    /**
     * List of pre Packaged concept set
     * @type {Array<PrePackagedConceptSetEnum>}
     * @memberof DataSetRequest
     */
    prePackagedConceptSet?: Array<PrePackagedConceptSetEnum>;
    /**
     * The ids of all concept sets in the data set
     * @type {Array<number>}
     * @memberof DataSetRequest
     */
    conceptSetIds?: Array<number>;
    /**
     * The ids of all cohorts in the data set
     * @type {Array<number>}
     * @memberof DataSetRequest
     */
    cohortIds?: Array<number>;
    /**
     * All the selected domain/value pairs in the data set
     * @type {Array<DomainValuePair>}
     * @memberof DataSetRequest
     */
    domainValuePairs: Array<DomainValuePair>;
    /**
     * The ids of all feature sets in the data set
     * @type {Array<string>}
     * @memberof DataSetRequest
     */
    tanagraFeatureSetIds?: Array<string>;
    /**
     * The ids of all cohorts in the data set
     * @type {Array<string>}
     * @memberof DataSetRequest
     */
    tanagraCohortIds?: Array<string>;
}

/**
 * Check if a given object implements the DataSetRequest interface.
 */
export function instanceOfDataSetRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "domainValuePairs" in value;

    return isInstance;
}

export function DataSetRequestFromJSON(json: any): DataSetRequest {
    return DataSetRequestFromJSONTyped(json, false);
}

export function DataSetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataSetId': !exists(json, 'dataSetId') ? undefined : json['dataSetId'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'etag': !exists(json, 'etag') ? undefined : json['etag'],
        'workspaceId': !exists(json, 'workspaceId') ? undefined : json['workspaceId'],
        'includesAllParticipants': !exists(json, 'includesAllParticipants') ? undefined : json['includesAllParticipants'],
        'prePackagedConceptSet': !exists(json, 'prePackagedConceptSet') ? undefined : ((json['prePackagedConceptSet'] as Array<any>).map(PrePackagedConceptSetEnumFromJSON)),
        'conceptSetIds': !exists(json, 'conceptSetIds') ? undefined : json['conceptSetIds'],
        'cohortIds': !exists(json, 'cohortIds') ? undefined : json['cohortIds'],
        'domainValuePairs': ((json['domainValuePairs'] as Array<any>).map(DomainValuePairFromJSON)),
        'tanagraFeatureSetIds': !exists(json, 'tanagraFeatureSetIds') ? undefined : json['tanagraFeatureSetIds'],
        'tanagraCohortIds': !exists(json, 'tanagraCohortIds') ? undefined : json['tanagraCohortIds'],
    };
}

export function DataSetRequestToJSON(value?: DataSetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataSetId': value.dataSetId,
        'name': value.name,
        'description': value.description,
        'etag': value.etag,
        'workspaceId': value.workspaceId,
        'includesAllParticipants': value.includesAllParticipants,
        'prePackagedConceptSet': value.prePackagedConceptSet === undefined ? undefined : ((value.prePackagedConceptSet as Array<any>).map(PrePackagedConceptSetEnumToJSON)),
        'conceptSetIds': value.conceptSetIds,
        'cohortIds': value.cohortIds,
        'domainValuePairs': ((value.domainValuePairs as Array<any>).map(DomainValuePairToJSON)),
        'tanagraFeatureSetIds': value.tanagraFeatureSetIds,
        'tanagraCohortIds': value.tanagraCohortIds,
    };
}

